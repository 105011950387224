import { useState, useContext } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent, useMediaQuery } from "@mui/material/";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AppContext from "../../../AppContext";
import styles from "./AddRuleDialog.module.css";
import { BLOCK_RULES } from "../../../constants";
import i18n from "../../../i18n";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  rules: BlockRule[];
  setRules: (rules: BlockRule[]) => void;
  selNumBlocks: number;
  onError: (msg: string) => void;
  setChangedRule: (cond: boolean) => void;
};

export default function AddRuleDialog({
  isOpen,
  setIsOpen,
  rules,
  setRules,
  selNumBlocks,
  onError,
  setChangedRule,
}: Props) {
  const { allItems } = useContext(AppContext);
  const [selNumBlock, setSelNumBlock] = useState<number | null>(null);
  const [selectedRule, setSelectedRule] = useState<string | null>(null);
  const [ruleItems, setRuleItems] = useState<DrugOrSynonym[]>([]);
  const [isErrorNumBlock, setIsErrorNumBlock] = useState(false);
  const [isErrorRule, setIsErrorRule] = useState(false);
  const [isErrorDrugs, setIsErrorDrugs] = useState(false);
  const [lastSelectedNiceId, setLastSelectedDrugId] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");

  // Deal with the direction of the drug chips for responsive view:
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  function onClose() {
    setSelNumBlock(null);
    setSelectedRule(null);
    setRuleItems([]);
    setIsErrorNumBlock(false);
    setIsErrorRule(false);
    setIsErrorDrugs(false);
    setIsOpen(false);
    setChangedRule(true);
  }

  const allSynonyms = allItems.filter((item) => item.type === "synonym");
  const selDrugIds = ruleItems.map((item) => item.drug_id);
  const drugOptions = allSynonyms.filter((item) => {
    if (item.nice_id === lastSelectedNiceId) {
      return true;
    }
    return !selDrugIds.includes(item.drug_id);
  });

  function createNewRule() {
    setIsErrorNumBlock(selNumBlock === null);
    setIsErrorRule(selectedRule === null);
    setIsErrorDrugs(ruleItems.length === 0);

    if (selNumBlock !== null && selectedRule !== null && ruleItems.length > 0) {
      const newRule = {
        blockId: null,
        blockNumber: selNumBlock,
        drugItems: ruleItems,
        ruleId: selectedRule,
        ruleDesc: BLOCK_RULES.filter((item) => item.id === selectedRule)[0].description,
      };
      setRules([...rules, newRule]);
      onClose();
    }
  }

  // Set of chips for each selected drug
  const selDrugChips = (
    <Stack
      direction={isSmallScreen ? "column" : "row"}
      spacing={1}
      sx={{ marginTop: 2, overflow: "scroll", maxHeight: "15vh" }}
    >
      {ruleItems.map((item) => (
        <Chip
          label={item.name}
          sx={{
            dsplay: "flex",
            justifyContent: "space-between",
          }}
          key={`chip-${item.nice_id}`}
          onDelete={() =>
            setRuleItems((prevState) =>
              prevState.filter((i) => i.nice_id !== item.nice_id),
            )
          }
        />
      ))}
    </Stack>
  );

  // Generate options for choosing the block number
  const blocksOptions: number[] = [];
  for (let i = 1; i < selNumBlocks + 1; i += 1) {
    blocksOptions.push(i);
  }

  // RULE SECURITY RELATED FUNCTIONS:

  /**
   * This method returns the BlockRule containing the drug with the id drugId.
   * @param drugId the id of a drug
   * @returns the corresponding BlockRule or null if no corresponding BlockRule exists
   */
  function getRuleDrugId(drugId: number): BlockRule | null {
    // Loop across all blockrules:
    for (let i = 0; i < rules.length; i += 1) {
      if (getDrugIds(i).includes(drugId)) {
        return rules[i];
      }
    }
    return null;
  }

  /**
   *
   * @param ruleNumber the index of a blockrule
   * @returns All drug ids contained in the selected blockrule
   */
  function getDrugIds(ruleNumber: number): number[] {
    return rules[ruleNumber].drugItems.map((item) => item.drug_id);
  }

  /**
   * @returns true if the blockNumber given doesn't have a rule 4 associated to it
   * or is not the only block left without a rule 4,
   * false otherwise
   */
  function isAvaliableRule4(blockNumber: number | null) {
    // Deal with the case where the user hasn't chosen a ramp to apply the rule to yet
    if (blockNumber === null) {
      return true;
    }

    // Count the number of avaliable blocks
    let numAvaliable: number = blockNumber;
    for (let i = 0; i < rules.length; i += 1) {
      if (rules[i].ruleId === BLOCK_RULES[3].id) {
        numAvaliable -= 1;

        // If the user chose a block containing a rule 4, return false:
        if (rules[i].blockNumber === blockNumber) {
          return false;
        }
      }
    }
    // Make sure that at least one block will stay rule 4-free:
    return numAvaliable > 0;
  }

  /**
   * A function to check whether the rule id and ramp numbers are avaliable:
   */
  const checkRules = (rampNumber: number, ruleId: string) => {
    // General except Rule 1:
    if (ruleId !== BLOCK_RULES[0].id) {
      let msg = `Cette règle ne peut pas être choisie pour cette rampe car `;
      msg += `une autre règle existe déjà pour cette rampe. `;
      msg += `Seule une règle 1 peut se supperposer aux autres règles.`;
      for (let i = 0; i < rules.length; i += 1) {
        if (
          rules[i].blockNumber === rampNumber &&
          rules[i].ruleId !== BLOCK_RULES[0].id
        ) {
          onError(msg);
          return false;
        }
      }
    }
    // Rule 4:
    if (ruleId === BLOCK_RULES[3].id && !isAvaliableRule4(rampNumber)) {
      let msg = `La règle ${BLOCK_RULES[3].description} ne peut pas être choisie pour cette lumière car `;
      msg += `elle est appliquée à toutes les lumières`;
      onError(msg);
      return false;
    }
    return true;
  };

  // Fuction to be called when the ramp number is changed
  const handleLightChange = (event: SelectChangeEvent) => {
    if (event.target.value === null) {
      return;
    }
    if (!selectedRule) {
      setSelNumBlock(Number(event.target.value));
      return;
    }
    if (checkRules(Number(event.target.value), selectedRule))
      setSelNumBlock(Number(event.target.value));
  };

  // Function to be called when the rule type is selected
  const handleRuleChange = (ruleId: string) => {
    if (!selNumBlock) {
      setSelectedRule(ruleId);
      return;
    }
    if (checkRules(selNumBlock, ruleId)) {
      setSelectedRule(ruleId);
    }
  };

  // Function to be called when a drug is added
  const handleAddDrug = (drugName: string | null) => {
    if (drugName !== null) {
      const item = drugOptions.filter((i) => i.name === drugName)[0];
      // check if there is already a rule on this drugId
      const existingRule = getRuleDrugId(item.drug_id);

      if (existingRule === null) {
        setLastSelectedDrugId(item.nice_id);
        setRuleItems([...ruleItems, item]);
      } else {
        const msg = `Une règle existe déjà pour ce médicament: Lumière ${
          existingRule.blockNumber + 1
        }, règle: ${existingRule.ruleDesc}.`;
        onError(msg);
      }
    }
    setUserInput("");
  };

  return (
    <Dialog
      open={isOpen}
      className={styles.dialogRegle}
      PaperProps={{
        sx: {
          height: "fit-content",
          width: isSmallScreen ? "100%" : "70%",
          maxWidth: isSmallScreen ? "100vw" : "600px",
          overflow: "visible",
          padding: 2,
          margin: 1,
        },
      }}
      maxWidth={false}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
        }}
      >
        {i18n.t("Menu.AddRuleDialog.AddNewRule")}
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
          padding: 4,
          height: "100%",
        }}
      >
        {/* Select num block */}
        <FormControl required error={isErrorNumBlock}>
          <FormLabel>{i18n.t("Menu.AddRuleDialog.Light")}</FormLabel>
          <Select
            className={styles.select}
            sx={{
              width: 80,
              height: 30,
              boxSizing: "border-box",
              borderRadius: "5px",
              padding: "0px 10px",
              backgroundColor: "var(--green)",
              color: "var(--white)",
            }}
            value={String(selNumBlock || "")}
            onChange={handleLightChange}
            id="select-num-block-rule"
          >
            {blocksOptions.map((val) => (
              <MenuItem value={val} key={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Select rule to apply */}
        <FormControl sx={{ marginTop: 2 }} error={isErrorRule} required>
          <FormLabel>{i18n.t("Menu.AddRuleDialog.ApplyRule")}</FormLabel>
          <Select
            className={styles.select}
            sx={{
              width: "100%",
              height: "auto",
              boxSizing: "border-box",
              borderRadius: "5px",
              padding: "0px 10px",
              backgroundColor: "var(--green)",
              color: "var(--white)",
            }}
            value={String(selectedRule || "")}
            onChange={(event: SelectChangeEvent<string>) => {
              handleRuleChange(event.target.value);
            }}
            id="select-rule"
          >
            {BLOCK_RULES.map((item) => (
              <MenuItem
                sx={{
                  whiteSpace: "unset",
                }}
                value={item.id}
                key={item.id}
              >
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Drugs choice */}
        <FormControl sx={{ marginTop: 2 }} error={isErrorDrugs} required>
          <FormLabel>{i18n.t("Menu.AddRuleDialog.DrugSelection")}</FormLabel>
          <Autocomplete
            onChange={(event, drugName) => {
              handleAddDrug(drugName);
            }}
            clearOnEscape
            disablePortal
            filterSelectedOptions
            options={drugOptions.map((item) => item.name)}
            sx={{
              width: "90%",
              alignSelf: "flex-start",
              marginTop: 1,
              overflow: "visible",
            }}
            id="select-drug-rule"
            renderInput={(params) => {
              params.inputProps.value = userInput;
              return (
                <TextField
                  onChange={(event) => {
                    setUserInput(event.target.value);
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                />
              );
            }}
          />
        </FormControl>

        {/* Display of selected drugs */}
        {selDrugChips}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()}>{i18n.t("Menu.AddRuleDialog.Cancel")}</Button>
        <Button onClick={() => createNewRule()} id="add-rule-button">
          {i18n.t("Menu.AddRuleDialog.CreateRule")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

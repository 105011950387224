import { createContext } from "react";
import { INIT_INJ_DRUG_IDS, INIT_NUM_BLOCKS } from "./constants";
import BlocksSet from "./classes/BlocksSet";
import SettingSet from "./classes/SettingSet";
import Settings from "./classes/Settings";

export const defaultIncompApiData: IncompApiResp = {
  incompatibilities: {},
  fast_repartition: [],
  guide_administration: {},
  rcp_data: {},
};
export const defaultRcpData: RcpDataId = {
  rcp_data: {},
};
export const defaultAdminGuide: AdminGuideDataId = {
  guide_administration: {},
};

type AppContextType = {
  selNiceIds: string[]; // Nice ids of selected drugs
  setSelNiceIds: (niceIds: string[]) => void;
  selMedsOraux: string[]; // nice ids of selected med oraux
  setSelMedsOraux: (niceIds: string[]) => void;
  token: string | null; // token of the user
  niceIdState: string; // nice id of the selected drug
  setNiceId: (niceId: string) => void;
  niceIdAdminGuide: string; // nice id of the selected drug in the admin guide
  setNiceIdAdminGuide: (niceId: string) => void;
  sessionId: string | null;
  allItems: DrugSynonymArray; // A list of all the DrugOrSynonym avaliable in the app
  incompApiData: IncompApiResp;
  rcpDataId: RcpDataId;
  adminGuideId: AdminGuideDataId;
  selDrugIds: number[]; // ids of the selected drugs, contains at most the same number of elements that selNiceIds
  filterText: string;
  allMedsOraux: AllMedsOraux;
  medsOrauxInfos: MedOralInfos[];
  ivPrescriptionsData: InjectablePrescriptions[];
  setIvPrescriptionsData: (data: InjectablePrescriptions[]) => void;
  updateIvPrescriptionsData: boolean;
  setUpdateIvPrescriptionsData: (update: boolean) => void;
  setIsUnauthorizedInjectablePrescriptions: (err: boolean) => void;
  setIsInternalInjectablePrescriptions: (err: boolean) => void;
  oralPrescriptionsData: OralPrescriptions[];
  setOralPrescriptionsData: (data: OralPrescriptions[]) => void;
  updateOralPrescriptionsData: boolean;
  setUpdateOralPrescriptionsData: (update: boolean) => void;
  setIsUnauthorizedOralPrescriptions: (err: boolean) => void;
  setIsInternalOralPrescriptions: (err: boolean) => void;
  numBlocks: number;
  setNumBlocks: (num: number) => void;
  blocksSet: BlocksSet | null;
  updateBlockSet: () => void;
  isOptLoading: boolean;
  setIsOptLoading: (isLoading: boolean) => void;
  selExactOptVal: number;
  setSelExactOptVal: (val: number) => void;
  exactOptResult: BlockOptimizationData[][] | null;
  setExactOptResult: (a: BlockOptimizationData[][] | null) => void;
  onOptimizeBlocks: (algoName: string, setting_id: number | null) => Promise<void>; // Applies an optimization algorithm
  addSelItem: (niceId: string) => void;
  addSelItemRegle: (niceId: string) => void;
  removeSelItem: (niceId: string) => void;
  addMedOral: (niceId: string) => void;
  removeMedOral: (niceId: string) => void;
  resetSelItems: () => void;
  modifyFilterText: (text: string) => void;
  resetBlocksSet: () => void; // Sets the blockSet to the loading state
  settingSet: SettingSet; // The settings of the user (used for the rules).
  updateSettingSet: () => void; // A function to supdate the settingSet
  currentSetting: Settings; // The setting currently applied, depends on the ramp number
  isHelpDialogOpen: boolean; // Controlls the Help Dialog
  setIsHelpDialogOpen: (isOpen: boolean) => void;
  showNoInfoIncomp: boolean;
  setShowNoInfoIncomp: (show: boolean) => void;
  showCompatibilities: boolean;
  setShowCompatibilities: (show: boolean) => void;
  showCompMaterial: boolean;
  setShowCompMaterial: (show: boolean) => void;
  rampShown: boolean;
  isPrescriptionsDialogOpen: boolean;
  setIsPrescriptionsDialogOpen: (isOpen: boolean) => void;
  removeExactOptResults: () => void; // Removes the bar to select the optimal results and deletes the stored results.
  getDrugsFromNiceIds: (niceIds: string[]) => DrugOrSynonym[]; // Gets a list of DrugOrSynonym items with nice_ids corresponding to the input.
  setIsErrorDialogOpen: (isOpen: boolean) => void; // Controlls whether the error dialog is open.
  setErrorMessage: (message: string) => void; // Controlls the message displayed on the error dialog.
  applyBigsFirst: boolean; // If applyBigsFirst is set to true by setApplyBigsFirst, the bigs_first algorithm is applied.
  setApplyBigsFirst: (cond: boolean) => void;
  showInformationAlimentaires: boolean;
  setShowInformationAlimentaires: (show: boolean) => void;
  showInformationEcrasabilites: boolean;
  setShowInformationEcrasabilites: (show: boolean) => void;
  showModalitesPrises: boolean;
  setShowModalitesPrises: (show: boolean) => void;
  isEcrasableInfoFilterActive: boolean;
  setIsEcrasableInfoFilterActive: (show: boolean) => void;
  solventItems: DrugOrSynonym[];
};

const AppContext = createContext<AppContextType>({
  selNiceIds: [],
  setSelNiceIds: (niceIds: string[]) => {},
  selMedsOraux: [],
  setSelMedsOraux: () => {},
  token: null,
  niceIdState: "",
  setNiceId: () => {},
  niceIdAdminGuide: "",
  setNiceIdAdminGuide: () => {},
  sessionId: null,
  allItems: [],
  incompApiData: defaultIncompApiData,
  rcpDataId: defaultRcpData,
  adminGuideId: defaultAdminGuide,
  selDrugIds: INIT_INJ_DRUG_IDS,
  filterText: "",
  allMedsOraux: [],
  medsOrauxInfos: [],
  ivPrescriptionsData: [],
  setIvPrescriptionsData: () => [],
  updateIvPrescriptionsData: true,
  setUpdateIvPrescriptionsData: () => {},
  oralPrescriptionsData: [],
  setOralPrescriptionsData: () => [],
  updateOralPrescriptionsData: true,
  setUpdateOralPrescriptionsData: () => {},
  numBlocks: INIT_NUM_BLOCKS,
  setNumBlocks: () => {},
  blocksSet: null,
  updateBlockSet: () => {},
  isOptLoading: false,
  setIsOptLoading: () => {},
  selExactOptVal: -1,
  setSelExactOptVal: () => {},
  exactOptResult: null,
  onOptimizeBlocks: async () => {},
  addSelItem: () => {},
  addSelItemRegle: () => {},
  removeSelItem: () => {},
  resetSelItems: () => {},
  addMedOral: () => {},
  removeMedOral: () => {},
  modifyFilterText: () => {},
  resetBlocksSet: () => {},
  setExactOptResult: () => {},
  settingSet: new SettingSet([], INIT_NUM_BLOCKS),
  updateSettingSet: () => {},
  currentSetting: new Settings(INIT_NUM_BLOCKS, []),
  isHelpDialogOpen: false,
  setIsHelpDialogOpen: () => {},
  showNoInfoIncomp: false,
  setShowNoInfoIncomp: () => {},
  showCompatibilities: false,
  setShowCompatibilities: () => {},
  showCompMaterial: true,
  setShowCompMaterial: () => {},
  rampShown: false,
  isPrescriptionsDialogOpen: false,
  setIsPrescriptionsDialogOpen: () => {},
  removeExactOptResults: () => {},
  getDrugsFromNiceIds: (niceIds: string[]) => {
    return [];
  },
  setIsErrorDialogOpen: (isOpen: boolean) => {},
  setErrorMessage: (message: string) => {},
  applyBigsFirst: true,
  setApplyBigsFirst: (cond: boolean) => {},
  showInformationAlimentaires: false,
  setShowInformationAlimentaires: () => {},
  showInformationEcrasabilites: false,
  setShowInformationEcrasabilites: () => {},
  setIsUnauthorizedInjectablePrescriptions: () => {},
  setIsInternalInjectablePrescriptions: () => {},
  setIsUnauthorizedOralPrescriptions: () => {},
  setIsInternalOralPrescriptions: () => {},
  showModalitesPrises: false,
  setShowModalitesPrises: () => {},
  isEcrasableInfoFilterActive: false,
  setIsEcrasableInfoFilterActive: () => {},
  solventItems: [],
});

export default AppContext;
